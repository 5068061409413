@import url('https://fonts.googleapis.com/css2?family=KoHo&family=Niramit&display=swap');

html, body {
  height: 100%;
}

p, span, div {
  font-family: 'Niramit', sans-serif;
}

h1, h2, h3, h4, h5, h6  {
  font-family: 'KoHo', sans-serif;
}

.flex-grow {
  flex: 1 0 auto;
}

.loader {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
}

.coin-list-link {
  text-decoration: none; /* no underline */
  color: black
}
.coin-list-link-image {
  float: left;
}

.coin-list-link-name {
  margin: 15px !important;
}

.coin-list-card {
  min-height: 600px;
}

.custom-nav {
  -webkit-box-shadow: 20px 20px 20px #FC0;
  -moz-box-shadow:    20px 20px 20px #FC0;
  box-shadow:         20px 20px 20px #FC0 !important; 
  z-index:999;
}

.card {
  border-radius: 0;
  border: solid 1px rgb(94, 94, 94);
  box-shadow: 4px 5px 0px rgb(87, 87, 87);
}

.app-bar-name {
  margin-left: 13px !important;
  font-family: 'KoHo', sans-serif;
  letter-spacing: 4px;
  font-size: 18px;
  font-weight: 500;
}


